.ec-bg-dark {
  background-color: rgb(18, 19, 25);
}

.ec-bg-highlight {
  background-color: rgb(25, 26, 33);
}

.App {
}

.Card {
  background-color: rgb(27, 27, 35);
}

/*.draw2 {
   stroke-dasharray: 2338.5;
   stroke-dashoffset: 2338.5;
   animation: dash2 1s linear;
}

@keyframes dash2 {
   to { stroke-dashoffset: 0;}
}*/